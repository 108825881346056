export const navigation = [
  {
    key: 'foundations',
    title: 'Foundations',
    items: [
      {
        href: '/storybook/foundations/home',
        title: 'Home',
      },
      {
        href: '/storybook/foundations/colors',
        title: 'Colors',
      },
      {
        href: '/storybook/foundations/icons',
        title: 'Icons / images',
      },
      {
        href: '/storybook/foundations/tabs',
        title: 'Tabs',
      },
      {
        href: '/storybook/foundations/typography',
        title: 'Typography',
      },
      {
        href: '/storybook/foundations/yado',
        title: 'Yado',
      },
    ],
  },
  {
    key: 'components',
    title: 'Components',
    items: [
      {
        href: '/storybook/components/alert',
        title: 'Alert',
      },
      {
        href: '/storybook/components/background',
        title: 'Background',
      },
      {
        href: '/storybook/components/badge',
        title: 'Badge',
      },
      {
        href: '/storybook/components/button',
        title: 'Button',
      },
      {
        href: '/storybook/components/button-dropdown',
        title: 'Split button',
      },
      {
        href: '/storybook/components/button-card',
        title: 'Button card',
      },
      {
        href: '/storybook/components/card',
        title: 'Card',
      },
      {
        href: '/storybook/components/dropdown',
        title: 'Dropdown',
      },
      {
        href: '/storybook/components/empty-space',
        title: 'Empty space',
      },
      {
        href: '/storybook/components/helper',
        title: 'Helper',
      },
      {
        href: '/storybook/components/html-content',
        title: 'HTML content',
      },
      {
        href: '/storybook/components/list',
        title: 'List',
      },
      {
        href: '/storybook/components/loader',
        title: 'Loader',
      },
      {
        href: '/storybook/components/long-card',
        title: 'Long card',
      },
      {
        key: 'modal',
        title: 'Modal',
        items: [
          {
            href: '/storybook/components/modal/modal-alert',
            title: 'ModalAlert',
          },
          {
            href: '/storybook/components/modal/modal-blank',
            title: 'ModalBlank',
          },
          {
            href: '/storybook/components/modal/modal-confirm',
            title: 'ModalConfirm',
          },
          {
            href: '/storybook/components/modal/modal-submit-keywords',
            title: 'ModalSubmitKeywords',
          },
        ],
      },
      {
        href: '/storybook/components/notifications',
        title: 'Notifications',
      },
      {
        href: '/storybook/components/popover',
        title: 'Popover',
      },
      {
        href: '/storybook/components/progress',
        title: 'Progress',
      },
      {
        href: '/storybook/components/result-card',
        title: 'Result Card',
      },
      {
        href: '/storybook/components/row',
        title: 'Row',
      },
      {
        href: '/storybook/components/setting',
        title: 'Setting',
      },

      {
        href: '/storybook/components/side-panel',
        title: 'Side panel',
      },
      {
        href: '/storybook/components/table',
        title: 'Table',
      },
      {
        href: '/storybook/components/tooltip',
        title: 'Tooltip',
      },
      {
        href: '/storybook/components/toucan',
        title: 'Toucan',
      },
    ],
  },
  {
    key: 'form',
    title: 'Form',
    items: [
      {
        href: '/storybook/form/absolute-date',
        title: 'AbsoluteDate',
      },
      {
        href: '/storybook/form/absolute-date-range',
        title: 'AbsoluteDateRange',
      },
      {
        href: '/storybook/form/address',
        title: 'Address',
      },
      {
        href: '/storybook/form/american-ein',
        title: 'American EIN',
      },
      {
        href: '/storybook/form/autocomplete',
        title: 'Autocomplete',
      },
      {
        href: '/storybook/form/bank-account',
        title: 'Bank account',
      },
      {
        href: '/storybook/form/card',
        title: 'Checkbox & Radio card',
      },
      {
        href: '/storybook/form/checkbox',
        title: 'Checkbox',
      },
      {
        href: '/storybook/form/check-number',
        title: 'Check number',
      },
      {
        href: '/storybook/form/email',
        title: 'Email',
      },
      {
        href: '/storybook/form/file',
        title: 'File',
      },
      {
        href: '/storybook/form/french-rna',
        title: 'French RNA',
      },
      {
        href: '/storybook/form/french-siren',
        title: 'French SIREN',
      },
      {
        href: '/storybook/form/french-siret',
        title: 'French SIRET',
      },
      {
        href: '/storybook/form/input',
        title: 'Input',
      },
      {
        href: '/storybook/form/money',
        title: 'Money',
      },
      {
        href: '/storybook/form/numeric',
        title: 'Numeric',
      },
      {
        href: '/storybook/form/phone',
        title: 'Phone',
      },
      {
        href: '/storybook/form/radio',
        title: 'Radio',
      },
      {
        href: '/storybook/form/radio-card',
        title: 'Radio card',
      },
      {
        href: '/storybook/form/radio-backgrounds',
        title: 'Radio backgrounds',
      },
      {
        href: '/storybook/form/radio-buttons',
        title: 'Radio buttons',
      },
      {
        href: '/storybook/form/rlmc',
        title: 'RLMC',
      },
      {
        href: '/storybook/form/select',
        title: 'Select',
      },
      {
        href: '/storybook/form/select-bank-accounts',
        title: 'Select bank accounts',
      },
      {
        href: '/storybook/form/ssn',
        title: 'Social security number',
      },
      {
        href: '/storybook/form/switch',
        title: 'Switch',
      },
      {
        href: '/storybook/form/timezone',
        title: 'Timezone',
      },
      {
        href: '/storybook/form/url',
        title: 'Url',
      },
    ],
  },
  {
    key: 'public',
    title: 'Public',
    items: [
      {
        href: '/storybook/public/components',
        title: 'Components',
      },
    ],
  },
]

import {
  getNonprofitAccountingSettings,
  getNonprofitAccountingSpecification,
  getOrganizationPspSubWallets,
  getPlatformSpecifications,
} from 'api'
import { IApiNonprofit } from 'api/type'
import { IOrganization } from 'providers/Organization/type'
import { iriToId } from 'utils'

import { ESetting, ISettingTypeMap } from './type'

export const getAccountingSetting = async (
  nonprofitId: IApiNonprofit['id']
): Promise<ISettingTypeMap[ESetting.ACCOUNTING]> => {
  const nonprofitAccountingSettings = await getNonprofitAccountingSettings(
    nonprofitId
  )
  const accountingSpecification = await getNonprofitAccountingSpecification(
    nonprofitId
  )
  return {
    ...nonprofitAccountingSettings,
    ...accountingSpecification,
  }
}

export const getPlatformSetting = async (
  nonprofitPlatform: IApiNonprofit['platform']
): Promise<ISettingTypeMap[ESetting.PLATFORM_SPECIFICATIONS]> =>
  getPlatformSpecifications(iriToId(nonprofitPlatform))

export const getPspSubWalletSetting = async (
  organizationId: IOrganization['id']
): Promise<ISettingTypeMap[ESetting.PSP_SUB_WALLET]> => {
  const subWalletsList = await getOrganizationPspSubWallets(organizationId)
  return {
    main: subWalletsList.data.find((subWallet) => subWallet.isCurrent) || null,
    secondary:
      subWalletsList.data.find((subWallet) => !subWallet.isCurrent) || null,
  }
}

import { getOrganizationNotifications } from 'api'
import { IApiNotification } from 'api/type'
import { NotificationsContext } from 'providers'
import React, { useState } from 'react'
import { useAsyncRetry } from 'react-use'

const NotificationsProvider: React.FC<{
  initialData?: Array<IApiNotification>
  children: React.ReactNode
}> = ({ initialData, children }) => {
  const [isFirstRender, setIsFirstRender] = useState(true)

  const fetchNotifications = useAsyncRetry(async () => {
    if (initialData && isFirstRender) {
      setIsFirstRender(false)
      return initialData
    }
    return getOrganizationNotifications()
  }, [initialData])

  return (
    <NotificationsContext.Provider
      value={{
        ...fetchNotifications,
        notifications: fetchNotifications.value,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export default NotificationsProvider

import styled from 'styled-components'

export const NotificationWrapper = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  width: 100%;
  padding: 0 2.813rem;
  position: fixed;
  bottom: 40px;
  left: 0;
  z-index: 889;
  pointer-events: none;

  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    width: calc(100% - 220px);
    left: 220px;
  }
`

export const MinorNotificationsWrapper = styled.div`
  position: fixed;
  z-index: 999;
  bottom: 0;
  right: 0;
`

import classNames from 'classnames'
import { Button, Col, Row, Title } from 'components'
import { ECtaType, ENotificationStatus } from 'providers/type'
import { FC } from 'react'
import { EColor } from 'types'

import { PopupAlert } from './style'
import { IPopupNotification } from './type'

const PopupNotification: FC<IPopupNotification> = ({
  id,
  ctas,
  status,
  handleRead,
  content,
  title,
  type,
  ...rest
}) => {
  const onClose = () => {
    handleRead(id, ENotificationStatus.DISMISSED)
  }

  return (
    <PopupAlert
      isOpen={status === ENotificationStatus.UNREAD}
      hasTitle={Boolean(title)}
      toggle={onClose}
      className="p-4"
      {...rest}
    >
      {title && (
        <Title type="h6" className="pr-4">
          {title}
        </Title>
      )}
      <div className={classNames(!title && 'mt-4')}>{content}</div>

      <Row className="mt-4 justify-content-center">
        <Col xs="auto">
          <Row className="align-items-center">
            {ctas &&
              Object.entries(ctas).map(([key, cta]) => (
                <Col key={key} xs="auto">
                  <Button
                    onClick={() => {
                      cta.onClick()
                      handleRead(
                        id,
                        cta.type === ECtaType.PRIMARY
                          ? ENotificationStatus.READ
                          : ENotificationStatus.DISMISSED
                      )
                    }}
                    color={EColor.BLUE}
                    outline={cta.type === ECtaType.SECONDARY}
                    className="px-4"
                  >
                    {cta.text}
                  </Button>
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
    </PopupAlert>
  )
}

export default PopupNotification

import { Background, Button, Col, Icon, Row, Title } from 'components'
import { Radio } from 'components/Form/Radio/style'
import { ECtaType, ENotificationStatus } from 'providers/type'
import { FC } from 'react'
import { EColor } from 'types'

import { IInformationNotification } from './type'

const InformationNotification: FC<IInformationNotification> = ({
  id,
  ctas,
  content,
  title,
  type,
  handleRead,
  status,
  ...rest
}) => {
  const handleChange = () => {
    handleRead(id, ENotificationStatus.READ)
  }

  const ctasList = {
    primary: ctas?.find((cta) => cta.type === ECtaType.PRIMARY),
    secondary: ctas?.find((cta) => cta.type === ECtaType.SECONDARY),
  }

  return (
    <Background {...rest}>
      <Row>
        <Col xs={1} className="text-center">
          <Radio
            id={id}
            type="radio"
            checked={status === ENotificationStatus.READ}
            onChange={handleChange}
          />
        </Col>
        <Col>
          <Row className="align-items-center">
            <Col xs="auto">
              <Title type="h6" color={EColor.DARK_GREY} className="mb-0">
                {title}
              </Title>
            </Col>
            {ctasList.primary && (
              <Col xs="auto" className="pl-0">
                <Button
                  onClick={() => {
                    ctasList.primary?.onClick()
                  }}
                  color={EColor.TRANSPARENT}
                  className="px-2 text-dark"
                  title={ctasList.primary.text}
                >
                  <Icon
                    icon={['far', 'arrow-up-right-from-square']}
                    size="xs"
                  />
                </Button>
              </Col>
            )}
          </Row>

          <p className="mb-0">{content}</p>
          <div className="text-right">
            {ctasList.secondary && (
              <Button
                onClick={() => {
                  ctasList.secondary?.onClick()
                }}
                color={EColor.TRANSPARENT}
              >
                <strong>
                  {ctasList.secondary.text}
                  <Icon icon={['far', 'long-arrow-right']} className="ml-3" />
                </strong>
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </Background>
  )
}

export default InformationNotification

import { Button, Helper, Link } from 'components'
import { createIntl, useSelector, useTranslation } from 'hooks'
import { useOrganization } from 'providers'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { selectPerson, setSidePanel } from 'state'
import { EColor } from 'types'

import NotificationCenterIcon from '../NotificationCenterIcon'

const HeaderNavigation: FC = () => {
  const dispatch = useDispatch()
  const intl = createIntl('components_header')
  const translation = useTranslation(intl)
  const person = useSelector(selectPerson)
  const { organization } = useOrganization()

  // TODO Update at the end of notifications epic
  const displayNotificationButton = false

  const openHelpCenter = () => {
    dispatch(
      setSidePanel({
        actions: {},
        options: {},
        isOpen: true,
        template: 'HelpPanel',
        title: translation.translate(`sidePanel.title`, {
          firstname: person.firstName,
        }),
      })
    )
  }

  return (
    <>
      {displayNotificationButton && (
        <Link href={`/organization/${organization.idOld}/notifications`}>
          <Button color={EColor.TRANSPARENT} className="mt-2 mr-3">
            <NotificationCenterIcon />
          </Button>
        </Link>
      )}

      <Helper
        withText={true}
        onClick={openHelpCenter}
        button={{
          size: 'lg',
          backgroundColor: EColor.GREEN,
          textColor: EColor.WHITE,
        }}
        tooltip={{
          text: translation.translate('header.menu.helpCenter'),
        }}
        data-tour="tour-helpButton"
      />
    </>
  )
}

export default HeaderNavigation

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ESetting,
  ISettingTypeMap,
  ISettingTypeMapInitial,
} from 'hooks/useSettings/type'

export const settingsInitialState: ISettingTypeMapInitial = {
  [ESetting.ACCOUNTING]: undefined,
  [ESetting.BANKING_SETTINGS]: undefined,
  [ESetting.COLLECT]: undefined,
  [ESetting.SUBSCRIPTION]: undefined,
  [ESetting.PLATFORM_SPECIFICATIONS]: undefined,
  [ESetting.PSP_SUB_WALLET]: undefined,
  [ESetting.PSP_WALLET]: undefined,
  [ESetting.CRM]: undefined,
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState: settingsInitialState,
  reducers: {
    resetSettings: () => settingsInitialState,
    setSettings: (state, action: PayloadAction<ISettingTypeMap>) =>
      action.payload,
  },
})

export const { setSettings, resetSettings } = settingsSlice.actions
export default settingsSlice.reducer

import {
  EMenuBlockType,
  IGroupMenuBlock,
  IMenuHeaderBlock,
  IMenuLinkBlock,
} from 'api/type'
import { Menu as MenuParent, Svg } from 'components'
import MenuBottomLink from 'components/Menu/BottomLink'
import MenuHeader from 'components/Menu/Header'
import MenuHover from 'components/Menu/Hover'
import MenuItem, { parseIconPath } from 'components/Menu/Item'
import MenuItemChild from 'components/Menu/Item/Child'
import MenuItemChildIcon from 'components/Menu/Item/ChildIcon'
import MenuItemChildTitle from 'components/Menu/Item/ChildTitle'
import MenuItemHelp from 'components/Menu/Item/Help'
import MenuSubscription from 'components/Menu/Subscription'
import {
  createIntl,
  useMenu,
  useSelector,
  useStore,
  useTranslation,
} from 'hooks'
import { useOrganization } from 'providers'
import { FC, useMemo } from 'react'
import { RawIntlProvider } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { selectPage, selectPerson, setSidePanel } from 'state'
import { selectMenu } from 'state/Menu/selectors'
import { EColor } from 'types'

import * as Type from './type'

const Menu: FC<Type.IMenu> = ({ color, isHidden }) => {
  const { organization } = useOrganization()
  const person = useSelector(selectPerson)
  const intl = createIntl('layouts_admin_menu')
  const translation = useTranslation(intl)
  const dispatch = useDispatch()
  const store = useStore()
  const page = useSelector(selectPage)
  const menuItemsData = useSelector(selectMenu)

  // header items
  const menuHeaderItem = useMemo<IMenuHeaderBlock | undefined>(() => {
    if (menuItemsData.length > 0) {
      const headerItem = menuItemsData.filter(
        (item) => item['@type'] === EMenuBlockType.HEADER_BLOCK
      )[0] as IMenuHeaderBlock

      return headerItem
    }
    return undefined
  }, [menuItemsData])

  // content items
  const menuContentItems = useMemo<
    Array<IMenuLinkBlock | IGroupMenuBlock> | null | undefined
  >(() => {
    if (menuItemsData.length > 0) {
      const headerItems = menuItemsData.filter(
        (item) => item['@type'] !== EMenuBlockType.HEADER_BLOCK
      ) as Array<IMenuLinkBlock | IGroupMenuBlock>

      return headerItems
    }
    return undefined
  }, [menuItemsData])

  const currentLocation = useLocation().pathname

  // Get current subitem menu id
  const pageItemId = useMemo(() => {
    if (page.menuItemActiveId) {
      return page.menuItemActiveId
    }

    return menuContentItems?.find((item) => {
      if (item['@type'] === EMenuBlockType.GROUP_MENU_BLOCK) {
        return item.links.find((link) => link.path === currentLocation)
      }
      return item.path === currentLocation
    })?.id
  }, [currentLocation, menuContentItems, page])

  const menu = useMenu(pageItemId, menuContentItems)

  const isGroup =
    store.isLoaded &&
    organization.isAdvanced &&
    !organization.hasIndependentCommunity
  color = isGroup ? EColor.GREEN : color

  const handleOnClick = () => {
    dispatch(
      setSidePanel({
        actions: {},
        options: {},
        isOpen: true,
        template: 'HelpPanel',
        title: translation.translate('sidePanel.title', {
          firstname: person.firstName,
        }),
        subtitle: translation.translate('sidePanel.subtitle'),
      })
    )
  }

  if (!menuItemsData.length || !menuHeaderItem || !menuContentItems) {
    return <></>
  }

  return (
    <MenuParent isHidden={isHidden} menu={menu} color={color}>
      <RawIntlProvider value={intl}>
        {menuHeaderItem.trialBlock !== null && (
          <MenuSubscription trialBlockContent={menuHeaderItem.trialBlock} />
        )}
        <MenuHeader menu={menu} headerItems={menuHeaderItem} color={color} />
        <MenuHover positionY={menu.hoverPositionY} />
        <div id="menu__items">
          {menuContentItems.map((item, index) => {
            const isLinkMenuBlock =
              item['@type'] === EMenuBlockType.LINK_MENU_BLOCK

            // If normal menu item (different from highlighted as settings)
            if ((isLinkMenuBlock && !item.highlighted) || !isLinkMenuBlock) {
              return (
                <MenuItem
                  menu={menu}
                  clickedId={menu.itemClickedId}
                  hoverId={menu.itemHoverId}
                  currentPageItemId={pageItemId ?? ''}
                  href={isLinkMenuBlock ? item.path : undefined}
                  icon={item.icon}
                  id={item.id}
                  key={item.id}
                  color={color}
                  label={item.label}
                >
                  {!isLinkMenuBlock &&
                    item.links.map((child, childIndex) => {
                      // Child title & separator

                      if (child.links) {
                        return (
                          <span key={child.id}>
                            <MenuItemChildTitle
                              key={child.id}
                              isFirstElement={childIndex === 0}
                            >
                              {child.label}
                            </MenuItemChildTitle>
                            {child.links.map((subChild) => (
                              <MenuItemChild
                                href={subChild.path}
                                key={subChild.id}
                                label={subChild.label}
                                id={subChild.id}
                              />
                            ))}
                          </span>
                        )
                      }

                      if (!child.path) {
                        return child.label ? (
                          // Child title
                          <MenuItemChildTitle key={child.id}>
                            {child.label}
                          </MenuItemChildTitle>
                        ) : (
                          // Child separator
                          <div className="mt-3" key={child.id} />
                        )
                      }
                      // Child with icon
                      if (child.icon) {
                        return (
                          <MenuItemChildIcon
                            className="mt-3"
                            href={child.path}
                            icon={child.icon}
                            key={child.id}
                            label={child.label}
                          />
                        )
                      }

                      // Child
                      return (
                        <MenuItemChild
                          href={child.path}
                          key={child.id}
                          label={child.label}
                          id={child.id}
                        />
                      )
                    })}
                </MenuItem>
              )
            }

            if (item.highlighted) {
              return (
                <MenuBottomLink
                  key={item.id}
                  href={item.path}
                  color={color}
                  label={item.label}
                  icon={
                    <Svg
                      src={parseIconPath(item.icon)}
                      className="ml-auto"
                      width="18px"
                      color={EColor.WHITE}
                    />
                  }
                />
              )
            }
            return undefined
          })}
        </div>
        <MenuItemHelp onClick={() => handleOnClick()} color={color} />
      </RawIntlProvider>
    </MenuParent>
  )
}

export default Menu

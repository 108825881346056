import {
  getOrganizationCollectSpecifications,
  getOrganizationCrmSettings,
  getOrganizationCrmSpecifications,
  getOrganizationPspWallet,
} from 'api'
import { useApiError, useSelector } from 'hooks'
import { useNonprofit, useOrganization } from 'providers'
import { useDispatch } from 'react-redux'
import { selectSettings, setSettings } from 'state'

import * as Type from './type'
import { ISettingTypeMap } from './type'
import {
  getAccountingSetting,
  getPlatformSetting,
  getPspSubWalletSetting,
} from './utils'

const useSettings = () => {
  const settings = useSelector(selectSettings)
  const { organization } = useOrganization()
  const { nonprofit } = useNonprofit()
  const dispatch = useDispatch()
  const apiError = useApiError()

  const getSetting = async <T extends keyof ISettingTypeMap>(
    setting: T,
    refreshData = false,
    errorHandler?:
      | boolean
      | {
          allowedErrorCodes?: Array<number>
          callback?: () => void
        }
  ): Promise<ISettingTypeMap[T]> => {
    // Setting already exists in the store
    if (settings[setting] !== undefined && !refreshData) {
      return settings[setting]
    }

    const newSetting: Partial<ISettingTypeMap> = {}
    try {
      // Fetch setting and update store
      switch (setting) {
        case Type.ESetting.ACCOUNTING: {
          newSetting[Type.ESetting.ACCOUNTING] = await getAccountingSetting(
            nonprofit.id
          )
          break
        }
        case Type.ESetting.PLATFORM_SPECIFICATIONS: {
          newSetting[Type.ESetting.PLATFORM_SPECIFICATIONS] =
            await getPlatformSetting(nonprofit.platform)
          break
        }
        case Type.ESetting.PSP_SUB_WALLET: {
          newSetting[Type.ESetting.PSP_SUB_WALLET] =
            await getPspSubWalletSetting(organization.id)
          break
        }
        case Type.ESetting.PSP_WALLET: {
          newSetting[Type.ESetting.PSP_WALLET] = await getOrganizationPspWallet(
            organization.id
          )
          break
        }
        case Type.ESetting.COLLECT: {
          newSetting[Type.ESetting.COLLECT] =
            await getOrganizationCollectSpecifications(organization.id)
          break
        }
        case Type.ESetting.CRM: {
          const [crmSettings, crmSpecifications] = await Promise.all([
            getOrganizationCrmSettings(organization.id),
            getOrganizationCrmSpecifications(organization.id),
          ])

          const { '@id': idSetting, ...crmSettingsUpdated } = crmSettings
          const { '@id': idSpecification, ...crmSpecificationsUpdated } =
            crmSpecifications

          newSetting[Type.ESetting.CRM] = {
            idSetting,
            idSpecification,
            ...crmSettingsUpdated,
            ...crmSpecificationsUpdated,
          }

          break
        }
      }
    } catch (error: unknown) {
      if (errorHandler !== false) {
        const allowedErrorCodes =
          errorHandler === true || errorHandler?.allowedErrorCodes === undefined
            ? []
            : errorHandler.allowedErrorCodes

        apiError.handleApiError(error, {
          allowedErrorCodes,
        })
        newSetting[setting] = null
        if (errorHandler !== true && errorHandler?.callback) {
          errorHandler?.callback()
        }
      }
    }
    const settingsUpdated = {
      ...settings,
      ...newSetting,
    }
    dispatch(setSettings(settingsUpdated))
    return settingsUpdated[setting]
  }

  return {
    getSetting,
  }
}

export default useSettings

import { library } from '@fortawesome/fontawesome-svg-core'
// Fad
import {
  faCheckCircle as fadCheckCircle,
  faCircle as fadCircle,
  faEye as fadEye,
  faPaperPlane as fadPaperPlane,
  faSpinnerThird as fadSpinnerThird,
  faUserPlus as fadUserPlus,
  faVideo as fadVideo,
  faWallet as fadWallet,
} from '@fortawesome/pro-duotone-svg-icons'
// Fal
import {
  faArrowUp as falArrowUp,
  faCoins as falCoins,
  faEnvelope as falEnvelope,
  faGlobe as falGlobe,
  faLightbulb as falLightBulb,
  faLongArrowLeft as falLongArrowLeft,
  faLongArrowRight as falLongArrowRight,
  faPaperclipVertical as falPaperclipVertical,
  faPhone as falPhone,
  faPlus as falPlus,
  faPlusCircle as falPlusCircle,
  faSpinnerThird as falSpinnerThird,
} from '@fortawesome/pro-light-svg-icons'
// Far
import {
  faAddressBook as farAddressBook,
  faArrowRight as farArrowRight,
  faArrowUp as farArrowUp,
  faArrowUpRightFromSquare as farArrowUpRightFromSquare,
  faAt as farAt,
  faBullhorn as farBullhorn,
  faCalculator as farCalculator,
  faCalendarDay as farCalendarDay,
  faChartLineUp as farChartLineUp,
  faCheck as farCheck,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faCircleExclamation as farCircleExclamation,
  faClock as farClock,
  faCog as farCog,
  faCopy as farCopy,
  faCreditCard as farCreditCard,
  faCrown as farCrown,
  faFileAlt as farFileAlt,
  faGraduationCap as farGraduationCap,
  faHeadset as farHeadset,
  faHomeHeart as farHomeHeart,
  faHourglassStart as farHourglassStart,
  faIdCardAlt as farIdCardAlt,
  faInboxIn as farInboxIn,
  faLightbulb as farLightbulb,
  faLink as farLink,
  faLinkHorizontal as farLinkHorizontal,
  faLinkHorizontalSlash as farLinkHorizontalSlash,
  faLockKeyhole as farLockKeyhole,
  faLockKeyholeOpen as farLockKeyholeOpen,
  faLongArrowLeft as farLongArrowLeft,
  faLongArrowRight as farLongArrowRight,
  faMedal as farMedal,
  faPaperPlaneTop as farPaperPlaneTop,
  faPlayCircle as farPlayCircle,
  faSack as farSack,
  faSmile as farSmile,
  faStar as farStar,
  faTag as farTag,
  faTimes as farTimes,
  faTriangleExclamation as farTriangleExclamation,
  faTrianglePersonDigging as farTrianglePersonDigging,
  faUser as farUser,
} from '@fortawesome/pro-regular-svg-icons'
// Fas
import {
  faAngleDown as fasAngleDown,
  faArrowAltCircleDown as fasArrowAltCirlcleDown,
  faArrowAltCircleLeft as fasArrowAltCirlcleLeft,
  faArrowAltCircleRight as fasArrowAltCirlcleRight,
  faArrowAltCircleUp as fasArrowAltCirlcleUp,
  faArrowAltLeft as fasArrowAltLeft,
  faArrowAltRight as fasArrowAltRight,
  faArrowUp as fasArrowUp,
  faBadgePercent as fasBadgePercent,
  faBarcodeAlt as fasBarcodeAlt,
  faBars as fasBars,
  faBasketShopping as fasBasketShopping,
  faBell as fasBell,
  faBookOpen as fasBookOpen,
  faBox as fasBox,
  faBuilding as fasBuilding,
  faCalendar as fasCalendar,
  faCalendarDay as fasCalendarDay,
  faCaretDown as fasCaretDown,
  faCaretUp as fasCaretUp,
  faCheckCircle as fasCheckCircle,
  faChevronDown as fasChevronDown,
  faChevronRight as fasChevronRight,
  faChevronUp as fasChevronUp,
  faCircle as fasCircle,
  faCirclePlus as fasCirclePlus,
  faClock as fasClock,
  faClone as fasClone,
  faCog as fasCog,
  faCoin as fasCoin,
  faCoins as fasCoins,
  faComment as fasComment,
  faCrown as fasCrown,
  faDownload as fasDownload,
  faEllipsisH as fasEllipsisH,
  faEllipsisV as fasEllipsisV,
  faEnvelope as fasEnvelope,
  faEnvelopeOpenDollar as fasEnvelopeOpenDollar,
  faExclamation as fasExclamation,
  faExclamationCircle as fasExclamationCircle,
  faExclamationTriangle as fasExclamationTriangle,
  faEye as fasEye,
  faFan as fasFan,
  faFile as fasFile,
  faFileLines as fasFileLines,
  faFileUpload as fasFileUpload,
  faFolders as fasFolders,
  faGift as fasGift,
  faGlobe as fasGlobe,
  faGlobeEurope as fasGlobeEurope,
  faGraduationCap as fasGraduationCap,
  faHandHoldingDollar as fasHandHoldingDollar,
  faHeart as fasHeart,
  faHome as fasHome,
  faHomeHeart as fasHomeHeart,
  faHourglassHalf as fasHourglassHalf,
  faInboxIn as fasInboxIn,
  faInboxOut as fasInboxOut,
  faInfo as fasInfo,
  faInfoCircle as fasInfoCircle,
  faKey as fasKey,
  faList as fasList,
  faLock as fasLock,
  faLockAlt as fasLockAlt,
  faMapMarkerAlt as fasMapMarkerAlt,
  faMoneyCheck as fasMoneyCheck,
  faMoneyCheckEdit as fasMoneyCheckEdit,
  faNewspaper as fasNewspaper,
  faPaperPlane as fasPaperPlane,
  faPen as fasPen,
  faPenAlt as fasPenAlt,
  faPhone as fasPhone,
  faPlay as fasPlay,
  faPlus as fasPlus,
  faPortrait as fasPortrait,
  faQuestion as fasQuestion,
  faReceipt as fasReceipt,
  faRocketLaunch as fasRocketLaunch,
  faSack as fasSack,
  faSearch as fasSearch,
  faSignOut as fasSignOut,
  faSiren as fasSiren,
  faStar as fasStar,
  faTag as fasTag,
  faTasksAlt as fasTasksAlt,
  faTicket as fasTicket,
  faTimes as fasTimes,
  faTimesCircle as fasTimesCircle,
  faTrashAlt as fasTrashAlt,
  faUniversity as fasUniversity,
  faUnlock as fasUnlock,
  faUser as fasUser,
  faUserFriends as fasUserFriends,
  faUsers as fasUsers,
  faVideo as fasVideo,
  faWallet as fasWallet,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ComponentProps, FC } from 'react'

import * as Style from './style'

export const icons = [
  // Fas
  fasAngleDown,
  fasArrowAltLeft,
  fasArrowAltRight,
  fasArrowAltCirlcleDown,
  fasArrowAltCirlcleLeft,
  fasArrowAltCirlcleRight,
  fasArrowAltCirlcleUp,
  fasArrowUp,
  fasBars,
  fasBell,
  fasBasketShopping,
  fasBarcodeAlt,
  fasBadgePercent,
  fasBookOpen,
  fasBox,
  fasBuilding,
  fasCalendar,
  fasCalendarDay,
  fasCaretUp,
  fasCaretDown,
  fasCheckCircle,
  fasChevronDown,
  fasChevronRight,
  fasChevronUp,
  fasCircle,
  fasCirclePlus,
  fasClock,
  fasClone,
  fasCoin,
  fasCoins,
  fasCog,
  fasComment,
  fasCrown,
  fasDownload,
  fasEllipsisV,
  fasEllipsisH,
  fasEnvelope,
  fasEnvelopeOpenDollar,
  fasExclamation,
  fasExclamationCircle,
  fasExclamationTriangle,
  fasEye,
  fasFan,
  fasFile,
  fasFileLines,
  fasFileUpload,
  fasFolders,
  fasGift,
  fasGlobe,
  fasGlobeEurope,
  fasGraduationCap,
  fasHandHoldingDollar,
  fasHome,
  fasHeart,
  fasHomeHeart,
  fasHourglassHalf,
  fasInboxIn,
  fasInboxOut,
  fasInfoCircle,
  fasInfo,
  fasKey,
  fasList,
  fasLock,
  fasLockAlt,
  fasMoneyCheck,
  fasMoneyCheckEdit,
  fasNewspaper,
  fasPaperPlane,
  fasPen,
  fasPenAlt,
  fasPhone,
  fasPlay,
  fasPlus,
  fasPortrait,
  fasQuestion,
  fasReceipt,
  fasRocketLaunch,
  fasSack,
  fasSearch,
  fasSignOut,
  fasSiren,
  fasStar,
  fasTag,
  fasTasksAlt,
  fasTicket,
  fasTimes,
  fasTimesCircle,
  fasTrashAlt,
  fasExclamationTriangle,
  fasUniversity,
  fasUnlock,
  fasUser,
  fasUserFriends,
  fasUsers,
  fasVideo,
  fasWallet,
  fasMapMarkerAlt,
  // Fal
  falArrowUp,
  falCoins,
  falEnvelope,
  falGlobe,
  falLongArrowRight,
  falSpinnerThird,
  falLongArrowLeft,
  falPaperclipVertical,
  falPhone,
  falPlus,
  falPlusCircle,
  falLightBulb,
  // Far
  farAddressBook,
  farArrowRight,
  farArrowUp,
  farBullhorn,
  farLightbulb,
  farAt,
  farChartLineUp,
  farCalculator,
  farCalendarDay,
  farCheck,
  farChevronLeft,
  farChevronRight,
  farCircleExclamation,
  farClock,
  farCog,
  farCopy,
  farCreditCard,
  farCrown,
  farFileAlt,
  farGraduationCap,
  farHeadset,
  farHomeHeart,
  farHourglassStart,
  farIdCardAlt,
  farInboxIn,
  farLink,
  farLinkHorizontal,
  farLinkHorizontalSlash,
  farLockKeyhole,
  farLockKeyholeOpen,
  farLongArrowLeft,
  farLongArrowRight,
  farMedal,
  farPaperPlaneTop,
  farPlayCircle,
  farSack,
  farSmile,
  farTag,
  farTimes,
  farTriangleExclamation,
  farTrianglePersonDigging,
  farUser,
  farArrowUpRightFromSquare,
  farStar,
  // Fad
  fadSpinnerThird,
  fadCheckCircle,
  fadCircle,
  fadUserPlus,
  fadEye,
  fadWallet,
  fadPaperPlane,
  fadVideo,
]
library.add(...icons)

const Icon: FC<ComponentProps<typeof FontAwesomeIcon>> = ({ ...props }) => (
  <Style.Icon {...props} />
)

export default Icon

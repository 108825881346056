import {
  getCurrentAdmin,
  getOrganizationApps,
  getOrganizationNonprofit,
  getOrganizationNotifications,
} from 'api'

export const fetchInitialData = async (organizationId: string) => {
  const [nonprofitData, personData, applicationsData, notificationsData] =
    await Promise.all([
      getOrganizationNonprofit(organizationId),
      getCurrentAdmin(),
      getOrganizationApps(organizationId),
      getOrganizationNotifications(),
    ])

  return { nonprofitData, personData, applicationsData, notificationsData }
}

import { ReactNode } from 'react'

export const getNotificationDisplayDuration = (children: ReactNode): number => {
  if (!children) {
    return -1
  }
  const timerAttributes = {
    basis: 5000,
    coefficient: 150,
  }
  const childrenText: string = children.toString().trim()
  const wordsCount: number = childrenText.split(' ').length
  return timerAttributes.basis + wordsCount * timerAttributes.coefficient
}

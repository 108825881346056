import {
  IApiBankingSettings,
  IApiCrmSettings,
  IApiNonprofitAccountingSettings,
  IApiNonprofitAccountingSpecification,
  IApiOrganizationCollectSpecifications,
  IApiOrganizationCrmSpecifications,
  IApiOrganizationPspSubWallet,
  IApiOrganizationPspWallet,
  IApiOrganizationSubscriptionSpecifications,
  IApiPlatformSpecifications,
} from 'api/type'
import { Nullable } from 'types'

export enum ESetting {
  ACCOUNTING = 'ACCOUNTING',
  BANKING_SETTINGS = 'BANKING_SETTINGS',
  COLLECT = 'COLLECT',
  PLATFORM_SPECIFICATIONS = 'PLATFORM_SPECIFICATIONS',
  PSP_SUB_WALLET = 'PSP_SUB_WALLET',
  PSP_WALLET = 'PSP_WALLET',
  SUBSCRIPTION = 'SUBSCRIPTION',
  CRM = 'CRM',
}

export type TPspSubWalletSetting = {
  main: IApiOrganizationPspSubWallet | null
  secondary: IApiOrganizationPspSubWallet | null
}

export type IAccountingSettings = IApiNonprofitAccountingSettings &
  IApiNonprofitAccountingSpecification

export type ICrmSettings = Omit<IApiOrganizationCrmSpecifications, '@id'> &
  Omit<IApiCrmSettings, '@id'> & {
    idSetting: IApiCrmSettings['@id']
    idSpecification: IApiOrganizationCrmSpecifications['@id']
  }

export interface ISettingTypeMap {
  [ESetting.ACCOUNTING]: Nullable<IAccountingSettings>
  [ESetting.BANKING_SETTINGS]: Nullable<IApiBankingSettings>
  [ESetting.COLLECT]: Nullable<IApiOrganizationCollectSpecifications>
  [ESetting.PLATFORM_SPECIFICATIONS]: Nullable<IApiPlatformSpecifications>
  [ESetting.PSP_SUB_WALLET]: Nullable<TPspSubWalletSetting>
  [ESetting.PSP_WALLET]: Nullable<IApiOrganizationPspWallet>
  [ESetting.SUBSCRIPTION]: Nullable<IApiOrganizationSubscriptionSpecifications>
  [ESetting.CRM]: Nullable<ICrmSettings>
}

// TODO type better
export interface ISettingTypeMapInitial {
  [ESetting.ACCOUNTING]: undefined | Nullable<IAccountingSettings>
  [ESetting.BANKING_SETTINGS]: undefined | Nullable<IApiBankingSettings>
  [ESetting.COLLECT]:
    | undefined
    | Nullable<IApiOrganizationCollectSpecifications>
  [ESetting.PLATFORM_SPECIFICATIONS]:
    | undefined
    | Nullable<IApiPlatformSpecifications>
  [ESetting.PSP_SUB_WALLET]: undefined | Nullable<TPspSubWalletSetting>
  [ESetting.PSP_WALLET]: undefined | Nullable<IApiOrganizationPspWallet>
  [ESetting.SUBSCRIPTION]:
    | undefined
    | Nullable<IApiOrganizationSubscriptionSpecifications>
  [ESetting.CRM]: undefined | Nullable<ICrmSettings>
}

export type TGetSetting = <T extends keyof ISettingTypeMap>(
  setting: T,
  refreshData: boolean,
  errorHandler?:
    | boolean
    | { allowedErrorCodes?: number[]; callback?: () => void }
) => Promise<ISettingTypeMap[T]>

import { OrganizationContext } from 'providers'
import { useContext } from 'react'

import { IOrganization } from './type'

const useOrganization = (): {
  organization: IOrganization
  loading: boolean
  retry: () => void
} => {
  const context = useContext(OrganizationContext)
  const { organization, loading, retry } = context

  if (loading || organization === undefined) {
    return {
      organization: {} as IOrganization,
      loading,
      retry,
    }
  }

  return {
    organization,
    loading,
    retry,
  }
}

export default useOrganization

import { useSelector } from 'hooks'
import { useNotifications } from 'providers'
import { ENotificationStatus } from 'providers/type'
import { FC } from 'react'
import { selectNotifications } from 'state'

import * as Style from './style'
import { getNotifications, getToastNotifications } from './utils'

const NotificationsWrapper: FC = ({ ...rest }) => {
  const toastNotifications = useSelector(selectNotifications)
  const { notifications, handleReadNotification } = useNotifications()

  const unreadNotifications = notifications.filter(
    (notification) => notification.status === ENotificationStatus.UNREAD
  )

  return (
    <Style.NotificationWrapper {...rest}>
      {getToastNotifications(toastNotifications)}
      {getNotifications(unreadNotifications, handleReadNotification)}
    </Style.NotificationWrapper>
  )
}

export default NotificationsWrapper

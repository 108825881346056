import { useSelector } from 'hooks'
import { OrganizationContext } from 'providers'
import { FC, useContext } from 'react'
import { selectBrand } from 'state'
import { EProcessEnv } from 'types'

const LegacyRedirect: FC = () => {
  const { organization } = useContext(OrganizationContext)
  const brand = useSelector(selectBrand)

  // Developement env, redirect to legacy app
  if (process.env.NODE_ENV === EProcessEnv.DEVELOPMENT) {
    const { pathname, search, hostname } = window.location
    const extension = hostname.split('.').pop()
    const anchor = window.location.href.split('#')[1]

    // Handle redirect to legacy pages for local/prod
    if (!organization) {
      window.location.reload()
      return <></>
    }

    // "Local" slug for advanced organizations dependent on parent site
    window.location.href = `http://${organization.slug || 'local'}.${
      brand.name
    }-dev.${extension}${pathname}${search}${anchor ? `#${anchor}` : ''}`
  }
  // Production env, reload page to use index.php file in legacy app
  else if (process.env.NODE_ENV === EProcessEnv.PRODUCTION) {
    window.location.reload()
  }

  return <></>
}

export default LegacyRedirect

import { Link, Svg } from 'components'
import { useSelector } from 'hooks'
import { FC, MouseEvent, useEffect, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { usePrevious } from 'react-use'
import { selectPage } from 'state'
import { EColor } from 'types'
import { getDomElement, isInternalPath } from 'utils'
import { ESelectorType } from 'utils/Misc/type'

import * as Style from './style'
import * as Type from './type'

export const parseIconPath = (icon: string): string =>
  icon.replace(/^\/|\.svg$/g, '')

const MenuItem: FC<Type.IMenuItem> = ({
  children,
  className,
  clickedId,
  color,
  hoverId,
  href,
  icon,
  id,
  menu,
  label,
  currentPageItemId,
}) => {
  const [childrenHeight, setChildrenHeight] = useState<number | string>(0)
  const page = useSelector(selectPage)

  // Show children after mount component, currentPageItemId and id never change
  useEffect(() => {
    setChildrenHeight(id === currentPageItemId ? 'auto' : 0)
  }, [page, id, currentPageItemId])

  // Toggle children after clicking on an item
  const prevClickedId = usePrevious(clickedId)
  useEffect(() => {
    if (clickedId !== prevClickedId) {
      setChildrenHeight(id === clickedId ? 'auto' : 0)
    }
  }, [clickedId, id, prevClickedId])

  // Create a item link when a href is not empty
  const itemProps: { [key: string]: string | FC | undefined } = {
    id,
    hoverId,
    className,
  }
  if (href) {
    itemProps.tag = Link
    itemProps.href = href
    itemProps.target = isInternalPath(href) ? '_self' : '_blank'
  } else {
    itemProps.tag = 'div'
  }

  // Color of text and arrow
  const elementColor = hoverId === id ? color : EColor.WHITE

  const getIconColor = () => {
    if (hoverId === id) {
      return color
    }
    return EColor.WHITE
  }

  const handleClick = () => {
    // Save the clicked item to transfer it to MenuItem components after click
    menu.setItemClickedPreviousId(menu.itemClickedId)
    menu.setItemClickedId(menu.itemClickedId === id ? '' : id)
  }

  const handleAnimationStart = () => {
    const itemClicked = getDomElement(menu.itemClickedId, ESelectorType.ID)
    const itemClickedPreviousChildren = getDomElement(
      `menu__children-${menu.itemClickedPreviousId}`,
      ESelectorType.ID
    )

    if (itemClickedPreviousChildren && itemClicked) {
      const itemClickedPreviousChildrenRect =
        itemClickedPreviousChildren.getBoundingClientRect()
      if (
        itemClickedPreviousChildrenRect.top + window.scrollY <
        menu.hoverPositionY
      ) {
        menu.setHoverPositionY(
          itemClicked.getBoundingClientRect().top +
            window.scrollY -
            itemClickedPreviousChildrenRect.height
        )
      }
    }
  }

  const handleMouseEnter = (event: MouseEvent) => {
    const currentTarget = event.currentTarget
    menu.setHoverPositionY(
      currentTarget.getBoundingClientRect().top + window.scrollY
    )
    menu.setItemHoverId(currentTarget.id)
  }

  return (
    <Style.MenuItem onMouseEnter={handleMouseEnter} {...itemProps}>
      <Style.MenuItemInner
        color={elementColor}
        id={id}
        hoverId={hoverId}
        onClick={handleClick}
      >
        <Svg
          src={parseIconPath(icon)}
          width="24px"
          color={getIconColor()}
          className="mr-4 mr-md-3"
        />

        {label}
        {Array.isArray(children) && children.length !== 0 && (
          <Style.MenuItemInnerArrow
            src="common/icon/unicolor/chevron-right"
            width="12px"
            color={elementColor}
            className="ml-auto"
            clickedId={clickedId}
            id={id}
          />
        )}
      </Style.MenuItemInner>
      {Array.isArray(children) && children.length !== 0 && (
        <AnimateHeight
          duration={300}
          height={childrenHeight}
          onAnimationStart={handleAnimationStart}
        >
          <Style.MenuItemChildren id={`menu__children-${id}`}>
            {children}
          </Style.MenuItemChildren>
        </AnimateHeight>
      )}
    </Style.MenuItem>
  )
}

export default MenuItem

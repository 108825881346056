import { Icon as ReactIcon } from 'components'
import { darken } from 'polished'
import styled from 'styled-components'
import { EColor } from 'types'

export const IconChip = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  top: 3px;
  right: -2px;
  background: ${({ theme }) => theme[EColor.RED]};
`

export const Icon = styled(({ ...props }) => <ReactIcon {...props} />)`
  transition: color 0.15s ease;
  &:hover {
    color: ${({ theme }) => darken(0.1, theme.blue)};
  }
`

import { CustomInput } from 'reactstrap'
import styled, { css } from 'styled-components'
import { EColor } from 'types'

export const checkInputStyle = css`
  .custom-control-label {
    font-weight: 500;
    vertical-align: middle;

    &::after {
      cursor: pointer;
    }
  }
  /* Hack fix the right margin when there is no label */
  padding-left: 1.4rem;

  .custom-control-label {
    &::before {
      left: -1.4rem;
    }
    &::after {
      background-image: none !important;
      color: ${EColor.WHITE};
      text-align: center;
      border: 2px solid #fff;
      width: 5px;
      height: 10px;
      top: 5px;
      left: -12px;
      transform: rotateZ(45deg);
      border-top: 0;
      border-left: 0;
    }
    span:not(:empty) {
      display: inline-block;
      margin-left: 0.5rem;
    }
  }
`

export const checkInputDisabledStyle = css`
  .custom-control-label {
    &::after {
      opacity: 0;
    }
  }
`

export const Radio = styled(({ ...props }) => <CustomInput {...props} />)`
  ${checkInputStyle};
  ${({ disabled }) => disabled && checkInputDisabledStyle};
`

/* eslint-disable @typescript-eslint/no-explicit-any */
// Disable eslint on the whole file because objects are too unpredictable to be typed
export const isObject = (object: { [key: string]: any }): boolean =>
  typeof object === 'object' && !Array.isArray(object)

export const isArraysEqual = (arrA: any[], arrB: any[]): boolean =>
  arrA.length === arrB.length &&
  arrA.every((item, index) => {
    if (isObject(item) && isObject(arrB[index])) {
      return isObjectEqual(item, arrB[index])
    }
    return Object.is(item, arrB[index])
  })

export const isObjectEqual = (
  a: Record<string, any>,
  b: Record<string, any>
): boolean => {
  const keys1 = Object.keys(a)
  const keys2 = Object.keys(b)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    const valueA = a[key]
    const valueB = b[key]

    if (Array.isArray(valueA) && Array.isArray(valueB)) {
      if (!isArraysEqual(valueA, valueB)) {
        return false
      }
    } else if (isObject(valueA) && isObject(valueB)) {
      if (!isObjectEqual(valueA, valueB)) {
        return false
      }
    } else if (!Object.is(valueA, valueB)) {
      return false
    }
  }

  return true
}
/* eslint-enable @typescript-eslint/no-explicit-any */

import { LayoutIntlProvider, Loader, NotificationsWrapper } from 'components'
import { createIntl, useStoreI18n, useTracking } from 'hooks'
import { freePlans, trialPlans } from 'pages/Subscription/Plan/Choice/data'
import { RouterContext } from 'providers'
import { cloneElement, FC, ReactElement, useContext, useEffect } from 'react'
import { ELocale } from 'utils/Intl/type'

import { Content } from './components/'

export const Signup: FC = ({ children }) => {
  const intlPath = 'pages_signup'

  const tracking = useTracking()
  const storeI18n = useStoreI18n()
  const storeI18nData = storeI18n.initialize()
  const locale = storeI18nData.locale
  const intl = createIntl(intlPath, locale)
  const { urlSearchParameters } = useContext(RouterContext)

  const getSerenityPlan = () =>
    locale === ELocale.FR_FR ? 'SERENITE' : 'SERENITY_US'

  // Tracking initialization
  useEffect(() => {
    tracking.initialize(locale, true)
  }, [tracking, locale])

  let plan =
    urlSearchParameters?.subscription?.toUpperCase() || getSerenityPlan()
  // Plan is not valid
  if (
    !freePlans.concat(trialPlans.concat(['g'])).includes(plan.toLowerCase())
  ) {
    plan = 'SERENITE'
  }
  // Handle legacy free platforms
  if (plan === 'G') {
    plan = 'LIBERTE'
  }

  // Clone children element to add handleSteps prop
  const signupPage = cloneElement(children as ReactElement, {
    plan,
  })

  return (
    <LayoutIntlProvider value={intl}>
      <div className="d-flex flex-row">
        <Content>{signupPage}</Content>
      </div>
      <NotificationsWrapper />
      <Loader />
    </LayoutIntlProvider>
  )
}

export default Signup

import { IInput } from 'components/Form/Input/type'
import { ECountry } from 'utils/Intl/type'

export enum ENumberType {
  FIXED_LINE = 'FIXED_LINE',
  MOBILE = 'MOBILE',
  FIXED_LINE_OR_MOBILE = 'FIXED_LINE_OR_MOBILE',
}

export interface IPhone extends IInput {
  countryCode?: ECountry
  helpText?: string
  name: string
  label?: string
  numberType?: ENumberType
}

import { useApiError } from 'hooks'
import {
  ApplicationsProvider,
  NonprofitProvider,
  NotificationsProvider,
  OrganizationContext,
  PersonProvider,
} from 'providers'
import React, { useContext } from 'react'
import { useAsync } from 'react-use'

import { fetchInitialData } from './apiFetch'

const AdminProviders: React.FC = ({ children }) => {
  const apiError = useApiError()
  const { organization } = useContext(OrganizationContext)

  const { value: initialData } = useAsync(async () => {
    if (!organization) {
      return undefined
    }

    try {
      return await fetchInitialData(organization.id)
    } catch (error: unknown) {
      apiError.handleApiError(error)
      return null
    }
  }, [organization])

  if (!initialData || !organization) {
    return <></>
  }

  return (
    <NonprofitProvider
      organizationId={organization.id}
      initialData={initialData}
    >
      <PersonProvider
        organizationId={organization.id}
        initialData={initialData}
      >
        <ApplicationsProvider
          organizationId={organization.id}
          initialData={initialData}
        >
          <NotificationsProvider initialData={initialData.notificationsData}>
            {children}
          </NotificationsProvider>
        </ApplicationsProvider>
      </PersonProvider>
    </NonprofitProvider>
  )
}

export default AdminProviders

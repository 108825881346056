import { useSelector, useStore } from 'hooks'
import { FC, useEffect } from 'react'
import { usePrevious } from 'react-use'
import { selectPage } from 'state'

import MenuToggle from './Toggle'
import * as Style from './style'
import * as Type from './type'

const Menu: FC<Type.IMenu> = ({
  menu,
  children,
  color,
  border = null,
  isHidden = false,
  directLoading = false,
}) => {
  const page = useSelector(selectPage)
  const store = useStore()

  // Move the hover after the page loading
  const prevPage = usePrevious(page)
  useEffect(() => {
    if (page !== prevPage) {
      menu.handleMouseLeave()
    }
  }, [page, prevPage, menu])

  return (
    <div>
      <MenuToggle
        menuOpened={menu.menuOpened}
        handleClick={() => menu.setMenuOpened(!menu.menuOpened)}
        href={page.backLinkHref}
        color={color}
        title={page.title}
        isMenuHidden={isHidden}
      />
      {!isHidden && (
        <>
          <Style.Background
            menuOpened={menu.menuOpened}
            color={color}
            border={border}
            data-tour="tour-menu"
          />

          {(store.isLoaded || directLoading) && (
            <Style.Menu
              menuOpened={menu.menuOpened}
              onMouseLeave={menu.handleMouseLeave}
              role="navigation"
            >
              {children}
            </Style.Menu>
          )}
        </>
      )}
    </div>
  )
}

export default Menu

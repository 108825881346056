import { IApiNotification } from 'api/type'

export interface IUseNotifications {
  notifications: Array<INotification>
  isLoading: boolean
  retry: () => void
  handleReadNotification: (
    id: INotification['id'],
    status: ENotificationStatus
  ) => void
}

export interface INotificationsContext {
  notifications?: Array<IApiNotification>
  loading: boolean
  retry: () => void
}

export type TCta = {
  text: string
  type: ECtaType
  href?: string
  onClick?: () => void
}

export enum ECtaType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export type TUpdatedCta = {
  text: string
  onClick: () => void
  type: ECtaType
}

export enum ENotificationStatus {
  READ = 'READ',
  UNREAD = 'UNREAD',
  DISMISSED = 'DISMISSED',
}

export interface INotification extends Omit<IApiNotification, 'ctas'> {
  status: ENotificationStatus
  ctas?: Array<TUpdatedCta>
}

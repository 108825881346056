import { EApiCrmPersonRole } from 'api/Crm/type'
import { ESubscriptionType } from 'api/type'
import { TCta } from 'providers/type'
import { ReactElement } from 'react'
import { EBrand } from 'utils'

export enum ENotificationType {
  MAJOR = 'MAJOR',
  MINOR = 'MINOR',
  INFORMATION = 'INFORMATION',
}

type TNotificationRestrictions = {
  roles?: Array<EApiCrmPersonRole>
  subscriptions?: Array<ESubscriptionType>
  brand: EBrand
}

export interface IApiNotification {
  id: string
  content: string | ReactElement
  title: string
  type: ENotificationType
  ctas?: Array<TCta>
  restrictions: TNotificationRestrictions
}

import classNames from 'classnames'
import { Icon, InputGroup, InputGroupAddon, InputGroupText } from 'components'
import { useField } from 'formik'
import { FC, FormEvent, ReactNode, useEffect, useState } from 'react'
import AutoSuggest, {
  ChangeEvent,
  RenderSuggestionsContainerParams,
  SuggestionsFetchRequestedParams,
} from 'react-autosuggest'
import { connectAutoComplete } from 'react-instantsearch-core'
import { Input as ReactStrapInput } from 'reactstrap'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'
import { IAlgoliaInputProps } from './type'

const AlgoliaAutocompleteWidget: FC<Type.IAlgoliaAutocompleteWidget> = ({
  currentRefinement,
  getSuggestionValue,
  hits,
  invalid,
  name,
  noResult,
  onSuggestionCleared,
  onSuggestionSelected,
  placeholder,
  refine,
  renderSuggestion,
  disabled = false,
}) => {
  const [value, setValue] = useState(currentRefinement)
  const [valueSelected, setValueSelected] = useState(currentRefinement)
  const [field] = useField(name)

  useEffect(() => {
    if (field.value === '' && valueSelected !== '') {
      setValueSelected('')
      setValue('')
    }
  }, [field.value, valueSelected])

  const handleChange = (_event: FormEvent, param: ChangeEvent) => {
    if (!param.newValue) {
      onSuggestionCleared && onSuggestionCleared()
      setValueSelected('')
    }
    if (param.method === 'click') {
      setValueSelected(param.newValue)
    }
    setValue(param.newValue)
  }

  const handleBlur = () => {
    setValue(valueSelected || value)
  }

  const renderSuggestionsContainer = ({
    containerProps: { id, key, className, ref, role },
    children,
  }: RenderSuggestionsContainerParams) => (
    <>
      {currentRefinement && (
        <Style.AlgoliaAutocompleteWidgetContainer
          id={id}
          key={key}
          ref={ref}
          role={role}
          className={classNames('position-absolute', className)}
        >
          {children}
          {!hits.length && (
            <Style.AlgoliaAutocompleteWidgetNoResult>
              {noResult}
            </Style.AlgoliaAutocompleteWidgetNoResult>
          )}
        </Style.AlgoliaAutocompleteWidgetContainer>
      )}
    </>
  )

  const renderInputComponent = (inputProps: IAlgoliaInputProps): ReactNode => (
    <InputGroup>
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <span>
            <Icon icon="search" color={EColor.GREY} />
          </span>
        </InputGroupText>
      </InputGroupAddon>
      <ReactStrapInput invalid={invalid} disabled={disabled} {...inputProps} />
    </InputGroup>
  )

  return (
    <>
      <Style.AlgoliaAutocompleteWidget>
        <AutoSuggest
          suggestions={hits}
          onSuggestionsFetchRequested={(
            request: SuggestionsFetchRequestedParams
          ) => refine(request.value)}
          onSuggestionsClearRequested={() => refine()}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={(values) =>
            renderSuggestionsContainer(values)
          }
          renderInputComponent={renderInputComponent}
          inputProps={{
            placeholder,
            onChange: handleChange,
            onBlur: handleBlur,
            type: 'search',
            value,
          }}
          theme={{
            container: 'autosuggest',
            suggestionsContainer: '',
            suggestionsList: 'list-group',
            suggestion: 'list-group-item',
            suggestionFocused: 'active',
          }}
        />
      </Style.AlgoliaAutocompleteWidget>
    </>
  )
}

export default connectAutoComplete(AlgoliaAutocompleteWidget)

import { EBackgroundBorderColor } from 'components/Background/type'
import { FC } from 'react'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'

const FlipBackground: FC<Type.IFlipBackground> = ({
  border = EBackgroundBorderColor.SHADOW,
  color = EColor.WHITE,
  flip,
  ...rest
}) => (
  <Style.FlipBackground color={color} {...rest}>
    <Style.FlipBackgroundBody flip={flip}>
      <Style.FlipBackgroundFront color={color} border={border} flip={flip}>
        {flip.children.front}
      </Style.FlipBackgroundFront>
      <Style.FlipBackgroundBack color={color} border={border} flip={flip}>
        {flip.children.back}
      </Style.FlipBackgroundBack>
    </Style.FlipBackgroundBody>
  </Style.FlipBackground>
)

export default FlipBackground

import {
  EApiCrmPersonRole,
  ESubscriptionType,
  IApiNotification,
  IApiOrganizationSubscriptionSpecifications,
} from 'api/type'
import Cookies from 'universal-cookie'
import { EBrand, redirectTo } from 'utils'
import { ERedirectionType } from 'utils/Url/type'

import { ENotificationStatus, INotification, TCta } from './type'

export const handleCtaClick = (cta: TCta) => {
  cta.onClick && cta.onClick()
  cta.href && redirectTo(cta.href, undefined, ERedirectionType.BLANK)
}

const getFormattedCtas = (ctas?: Array<TCta>) => {
  if (!ctas) {
    return []
  }

  return ctas.map((cta) => ({
    text: cta.text,
    type: cta.type,
    onClick: () => handleCtaClick(cta),
  }))
}

const getNotificationsFilteredByRole = (
  notifications: Array<IApiNotification>,
  hasRole: (id: EApiCrmPersonRole) => boolean
): Array<IApiNotification> =>
  notifications.filter((notification) => {
    if (!notification.restrictions.roles) {
      return notification
    }

    return notification.restrictions.roles.some((role) => hasRole(role))
  })

const getNotificationsFilteredByBrand = (
  notifications: Array<IApiNotification>,
  brand: EBrand
): Array<IApiNotification> =>
  notifications.filter(
    (notification) => notification.restrictions.brand === brand.toUpperCase()
  )

const getNotificationsFilteredBySubscriptionType = (
  notifications: Array<IApiNotification>,
  subscriptionSpecifications: IApiOrganizationSubscriptionSpecifications
): Array<IApiNotification> => {
  const subscriptionChecks: Record<ESubscriptionType, boolean> = {
    [ESubscriptionType.PREMIUM]:
      subscriptionSpecifications.hasPayingSubscription,
    [ESubscriptionType.FREE]: subscriptionSpecifications.hasFreeSubscription,
    [ESubscriptionType.TRIAL]: subscriptionSpecifications.hasTrialSubscription,
  }

  return notifications.filter((notification) => {
    if (!notification.restrictions.subscriptions) {
      return notification
    }

    return notification.restrictions.subscriptions.some(
      (subscription) => subscriptionChecks[subscription]
    )
  })
}

export const filterNotifications = (
  notifications: Array<IApiNotification>,
  hasRole: (id: EApiCrmPersonRole) => boolean,
  brand: EBrand,
  subscriptionSpecifications: IApiOrganizationSubscriptionSpecifications
): Array<IApiNotification> => {
  let filteredNotifications = getNotificationsFilteredByRole(
    notifications,
    hasRole
  )
  filteredNotifications = getNotificationsFilteredByBrand(
    filteredNotifications,
    brand
  )
  filteredNotifications = getNotificationsFilteredBySubscriptionType(
    filteredNotifications,
    subscriptionSpecifications
  )

  return filteredNotifications
}

export const updateNotificationsWithStatus = (
  notifications: Array<IApiNotification>,
  cookies: Cookies
): Array<INotification> => {
  const updatedNotifications: Array<INotification> = []

  for (const { ctas, ...notification } of notifications) {
    const notificationStatus = cookies.get(`notification-${notification.id}`)

    updatedNotifications.push({
      ...notification,
      status: notificationStatus ?? ENotificationStatus.UNREAD,
      ctas: getFormattedCtas(ctas),
    })
  }
  return updatedNotifications
}

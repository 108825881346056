import { Link } from 'components'
import styled from 'styled-components'

export const MenuBottomLink = styled(({ color, ...rest }) => (
  <Link {...rest} />
))`
  display: block;
  padding: 5px 0;
  margin: 20px 0 0;
  transition: background-color linear 0.3s;
  background-color: ${({ theme, color }) => theme[`menuColors-${color}`]};
  &:hover {
    text-decoration: none;
    background-color: ${({ theme, color }) =>
      theme[`menuColorsHover-${color}`]}};
  }
`
export const MenuBottomLinkInner = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  height: 45px;
  padding: 0 20px;
  color: ${(props) => props.theme.white};
  cursor: pointer;
  display: flex;
  align-items: center;
`

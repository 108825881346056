import {
  Container,
  EmptySpaceAccessDenied,
  HasAccess,
  Header,
  LayoutIntlProvider,
  NotificationsWrapper,
  SidePanel,
  Tabs,
} from 'components'
import { useSelector, useStore } from 'hooks'
import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { resetSidePanel, selectPerson } from 'state'
import { EColor } from 'types'

import Menu from './components/Menu'

export const Master: FC = ({ children }) => {
  const dispatch = useDispatch()
  const store = useStore()
  const person = useSelector(selectPerson)

  // Initialize store
  useEffectOnce(() => {
    store.initializeMasterAdmin()
  })

  // Reset the sidePanel store at each layout rerender.
  useEffect(() => {
    dispatch(resetSidePanel())
  })

  if (person.isMasterAdmin === undefined) {
    return <></>
  }

  return (
    <LayoutIntlProvider>
      <HasAccess
        access={() => person.isMasterAdmin === true}
        onAccessDenied={() => <EmptySpaceAccessDenied />}
        onAccessGranted={() => (
          <>
            <Menu color={EColor.RED} />
            <Header />
            <Container className="my-4" role="main" tag="main">
              <Tabs />
              {children}
            </Container>
            <NotificationsWrapper />
            <SidePanel />
          </>
        )}
      />
    </LayoutIntlProvider>
  )
}

export default Master

import classNames from 'classnames'
import { useSelector } from 'hooks'
import { FC, MouseEvent, useMemo } from 'react'
import { useLocation } from 'react-router'
import { selectPage } from 'state'

import * as Style from './style'
import * as Type from './type'

const MenuItemChild: FC<Type.IMenuItemChild> = ({
  className,
  href,
  onClick,
  label,
  target,
  id,
}) => {
  const path = useLocation().pathname
  const page = useSelector(selectPage)

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      onClick(event)
    }
  }

  const isActive = useMemo(
    () => path === href || page.menuSubItemActiveId === id,

    [path, href, page.menuSubItemActiveId, id]
  )

  return (
    <Style.MenuItemChild
      onClick={(event: MouseEvent<HTMLAnchorElement>) => handleClick(event)}
      href={href}
      className={classNames(isActive && 'activeItem', className)}
      target={target}
    >
      {label}
    </Style.MenuItemChild>
  )
}

export default MenuItemChild

import { getPaymentRequest } from 'api'
import {
  EmptySpace,
  Form,
  LayoutIntlProvider,
  Loader,
  NotificationsWrapper,
} from 'components'
import Dinero from 'dinero.js'
import { Formik, FormikValues } from 'formik'
import {
  createIntl,
  useApiError,
  useIframeHeightListener,
  useTracking,
  useTranslation,
} from 'hooks'
import { EPaymentMethods } from 'pages/Payment/type'
import { getInitialTipsValues } from 'pages/Payment/utils'
import { RouterContext } from 'providers'
import React, {
  cloneElement,
  FC,
  isValidElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useAsync, useEffectOnce } from 'react-use'
import { Container } from 'reactstrap'
import { setI18n } from 'state'
import { EYado } from 'types'
import { decodeUrlParameters, getBrandName } from 'utils'

import Footer from './components/Footer'
import Header from './components/Header'
import Main from './components/Main'
import * as Type from './type'
import { EPaymentLayoutType, IPaymentLayout } from './type'

export const Payment: FC<IPaymentLayout> = ({ children, type }) => {
  const tracking = useTracking()
  const dispatch = useDispatch()
  const apiError = useApiError()
  const { requestId } = useParams<Type.IParams>()
  const [submitPayment, setSubmitPayment] = useState(false)
  const [sepaConfirmIsOpen, setSepaConfirmIsOpen] = useState(false)

  // Search parameter "payload" is used by payment failure and payment success pages
  const { urlSearchParameters } = useContext(RouterContext)

  const payload = decodeUrlParameters<Type.IPaymentPayload>(
    urlSearchParameters?.payload
  )

  const intlPath = 'pages_payment'

  const { isIframe, resizeIframe } = useIframeHeightListener()

  useEffectOnce(() => {
    if (isIframe) {
      resizeIframe()
    }
  })

  const [childrenWithProps, setChildrenWithProps] =
    useState<React.ReactElement>()

  const paymentPageConfigurationId =
    payload?.paymentPageConfigurationId !== undefined
      ? payload.paymentPageConfigurationId
      : requestId

  // Fetch page data
  const { value: pageData } = useAsync(async () => {
    // on error return payload instead of paymentRequest that could return 404
    if (type === EPaymentLayoutType.ERROR) {
      return payload
    }

    try {
      if (paymentPageConfigurationId !== undefined) {
        return await getPaymentRequest(paymentPageConfigurationId)
      } else {
        return null
      }
    } catch (error: unknown) {
      apiError.handleApiError(error)
      return undefined
    }
  })

  // Add pageData as props to children
  useEffect(() => {
    if (children && pageData) {
      isValidElement(children) &&
        setChildrenWithProps(() =>
          cloneElement(children as React.ReactElement, {
            pageData,
            paymentPageConfigurationId,
            submitHandler: {
              get: () => submitPayment,
              set: (value: boolean) => setSubmitPayment(value),
            },
            sepaHandler: {
              get: () => sepaConfirmIsOpen,
              set: (value: boolean) => setSepaConfirmIsOpen(value),
            },
          })
        )
    }
  }, [
    children,
    submitPayment,
    pageData,
    paymentPageConfigurationId,
    sepaConfirmIsOpen,
  ])

  const intl = createIntl(intlPath, pageData?.organization.nonprofit.locale)
  const translation = useTranslation(intl)

  // Tracking initialization
  useEffect(() => {
    if (pageData === undefined || pageData === null) {
      return
    }

    if (pageData.organization.nonprofit.locale) {
      tracking.initialize(pageData.organization.nonprofit.locale)
    }
  }, [pageData, tracking])

  useEffect(() => {
    if (pageData === undefined || pageData === null) {
      return
    }

    const i18nData = {
      isLoaded: true,
      brand: {
        name: pageData.organization.brand,
        publicName: getBrandName(pageData.organization.brand),
      },
      locale: pageData.organization.nonprofit.locale,
      timezone: pageData.organization.nonprofit?.timezone || 'Europe_Paris',
      country: pageData.organization.nonprofit.country,
    }

    dispatch(setI18n(i18nData))
  }, [dispatch, pageData])

  if (pageData === null) {
    return (
      <EmptySpace
        title={translation.translate('fallbackError.text')}
        yado={EYado.ACCESS_DENIED}
        button={{
          text: translation.translate('fallbackError.button'),
          onClick: () => window.history.back(),
        }}
      />
    )
  }

  if (!pageData || !childrenWithProps) {
    return <></>
  }

  Dinero.globalLocale = intl.locale

  return (
    <LayoutIntlProvider value={intl}>
      <Header pageData={pageData} />
      <Container className="mt-0 mt-md-4" role="main" tag="main">
        <Main>
          <Formik
            initialValues={{
              paymentAdditionalData: {},
              paymentData: '',
              paymentMethod: pageData?.paymentMethods?.length
                ? pageData.paymentMethods[0]
                : '',
              sepaTos: '',
              ...getInitialTipsValues(pageData.publicContext.TIPS_SUGGESTIONS),
              tos: '',
            }}
            validate={(values: FormikValues) => {
              const errors: { [key: string]: string } = {}

              // Display error message for negative tips
              if (parseFloat(values.tip_custom) < 0) {
                errors.tos = translation.translate('tips.error.negative')
              }

              // Display error message if sepa TOS have not been accepted
              if (
                values.paymentMethod === EPaymentMethods.ADYEN_SEPA &&
                !values.sepaTos &&
                sepaConfirmIsOpen
              ) {
                errors.sepaTos = translation.translate(
                  'sepa.confirm.tos.required'
                )
              }

              return errors
            }}
            onSubmit={() => setSubmitPayment(true)}
            children={() => <Form>{childrenWithProps}</Form>}
          />
        </Main>
      </Container>
      <Footer pageData={pageData} />
      <NotificationsWrapper />
      <Loader />
    </LayoutIntlProvider>
  )
}

export default Payment

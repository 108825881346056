import { Alert as ReactstrapAlert } from 'reactstrap'
import styled from 'styled-components'

export const PopupAlert = styled(({ hasTitle, ...props }) => (
  <ReactstrapAlert {...props} />
))`
  background-color: white;
  border-color: ${({ theme }) => theme.lightGrey};
  color: ${({ theme }) => theme.black};
  border-width: 1px;
  border-radius: 10px;
  max-width: 300px;
  pointer-events: all;

  ${({ hasTitle }) => hasTitle && '.close { margin-top: 9px; }'};
`

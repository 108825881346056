import { Background, Col, ResponsiveButton } from 'components'
import { EBackgroundBorderColor } from 'components/Background/type'
import { createIntl, useTranslation } from 'hooks'
import { FC, useState } from 'react'
import { RawIntlProvider } from 'react-intl'
import { EColor } from 'types'

import LongCardContent from './Content'
import LongCardHeader from './Header'
import * as Data from './data'
import * as Style from './style'
import * as Type from './type'

const LongCard: FC<Type.ILongCard> = ({
  actions = [],
  alert,
  items,
  isArchived = false,
  isDeployable = false,
  isColumn = false,
  status,
  title,
  ...rest
}) => {
  const intl = createIntl('components_longcard')
  const translation = useTranslation(intl)

  const [isOpen, setIsOpen] = useState(!isDeployable)
  return (
    <div {...rest}>
      <RawIntlProvider value={intl}>
        <Background
          color={isArchived ? EColor.TRANSPARENT : EColor.WHITE}
          border={
            isArchived
              ? EBackgroundBorderColor.SOLID
              : EBackgroundBorderColor.SHADOW
          }
        >
          <LongCardHeader
            actions={actions}
            isDeployable={isDeployable}
            isArchived={isArchived}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            status={status}
            title={title}
          />

          <LongCardContent
            alert={alert}
            items={items}
            isArchived={isArchived}
            isColumn={isColumn}
            isOpen={isOpen}
          />

          <Style.ResponsiveButtonsRow
            isArchived={isArchived}
            className="w-100 d-flex d-lg-none m-0 mt-3 pt-2 justify-content-between text-center"
          >
            {actions.map((action, key) => (
              <Col className="col-auto px-0" key={key}>
                <ResponsiveButton
                  icon={Data.responsiveButtonsIcons[action.name]}
                  className="mx-1 mt-3 mb-1 my-lg-0"
                  onClick={action.handler}
                  disabled={action.isDisabled}
                >
                  {translation.translate(`action.${action.name}`)}
                </ResponsiveButton>
              </Col>
            ))}
          </Style.ResponsiveButtonsRow>
        </Background>
      </RawIntlProvider>
    </div>
  )
}

export default LongCard

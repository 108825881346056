import { usePerson } from 'hooks'
import { NotificationsContext, useOrganization } from 'providers'
import { useContext, useMemo } from 'react'
import Cookies from 'universal-cookie'

import { ENotificationStatus, INotification, IUseNotifications } from './type'
import { filterNotifications, updateNotificationsWithStatus } from './utils'

const useNotifications = (): IUseNotifications => {
  const {
    notifications,
    loading: isNotificationLoading,
    retry,
  } = useContext(NotificationsContext)
  const { loading: isOrganizationLoading, organization } = useOrganization()
  const { isLoaded: isPersonLoaded, hasRole } = usePerson()
  const cookies = useMemo(() => new Cookies(), [])

  const handleReadNotification = (
    id: INotification['id'],
    status: ENotificationStatus
  ) => {
    cookies.set(`notification-${id}`, status)
    retry()
  }

  const updatedNotifications = useMemo(() => {
    if (
      isNotificationLoading ||
      !isPersonLoaded ||
      isOrganizationLoading ||
      notifications === undefined
    ) {
      return []
    }

    const { brand, subscription } = organization

    const filteredNotifications = filterNotifications(
      notifications,
      hasRole,
      brand,
      subscription.specifications
    )

    return updateNotificationsWithStatus(filteredNotifications, cookies)
  }, [
    isNotificationLoading,
    isPersonLoaded,
    isOrganizationLoading,
    notifications,
    cookies,
    hasRole,
    organization,
  ])

  return {
    notifications: updatedNotifications,
    handleReadNotification,
    isLoading: isNotificationLoading,
    retry,
  }
}

export default useNotifications

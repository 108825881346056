import { Alert } from 'components'
import styled from 'styled-components'

export const ToastNotification = styled(Alert)`
  max-width: 930px;
  margin: 0 auto;
  pointer-events: all;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 ${(props) => props.theme.grey};
  & > b,
  strong {
    font-weight: 500;
  }
`

import { Button, Display } from 'components'
import { Footer, Header, ModalContainer } from 'components/Modal/Confirm/style'
import { EModalSize } from 'components/Modal/type'
import { FC, useState } from 'react'
import { ModalBody } from 'reactstrap'
import { EColor } from 'types'

import * as Type from './type'

const ModalAlert: FC<Type.IModalAlert> = ({
  buttonText,
  callback,
  closeCallback,
  isOpen,
  buttonIsDisabled = false,
  size = EModalSize.SM,
  title,
  description,
  ...rest
}) => {
  const [isDisabled, setIsDisabled] = useState(false)

  const handleToggle = async () => {
    setIsDisabled(true)
    await callback()
    setIsDisabled(false)
  }

  return (
    <ModalContainer
      centered={true}
      isOpen={isOpen}
      size={size}
      toggle={() => handleToggle()}
      {...rest}
    >
      <Header
        className="pb-0"
        toggle={() => (closeCallback ? closeCallback() : handleToggle())}
      />
      <ModalBody className="py-0 mx-4">
        {title && (
          <Display type="h5" className="pb-2">
            {title}
          </Display>
        )}
        <div className="mb-1">{description}</div>
      </ModalBody>
      <Footer className="mb-2 text-center mx-4">
        <Button
          className="text-uppercase"
          color={EColor.BLUE}
          onClick={() => handleToggle()}
          disabled={buttonIsDisabled || isDisabled}
        >
          {buttonText}
        </Button>
      </Footer>
    </ModalContainer>
  )
}

export default ModalAlert

import { Link } from 'components'
import styled from 'styled-components'

export const MenuItemChild = styled((props) => <Link {...props} />)`
  display: block;
  padding-top: 6px;
  color: ${(props) => props.theme.white};
  opacity: 0.75;
  transition: opacity 0.3s;
  font-weight: 300;
  line-height: 19px;
  &:hover {
    color: ${(props) => props.theme.white};
    text-decoration: none;
    font-weight: 400;
  }
  &:first-of-type {
    padding-top: 0;
  }
  &.activeItem {
    font-weight: 500;
  }
`

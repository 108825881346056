import { ENotificationType } from 'api/type'
import {
  Button,
  Col,
  Link,
  ModalBlank,
  PopupNotification,
  Row,
  Title,
  ToastNotification,
} from 'components'
import { EModalSize } from 'components/Modal/type'
import { ECtaType, ENotificationStatus, INotification } from 'providers/type'
import { IStoreNotifications } from 'state/Notifications/type'
import { EColor } from 'types'

import { MinorNotificationsWrapper } from './style'

export const getNotifications = (
  notifications: Array<INotification>,
  handleRead: (id: INotification['id'], status: ENotificationStatus) => void
) => {
  if (!notifications.length) {
    return null
  }

  return (
    <>
      {getMajorNotifications(notifications, handleRead)}
      <MinorNotificationsWrapper className="p-4">
        {getMinorNotifications(notifications, handleRead)}
      </MinorNotificationsWrapper>
    </>
  )
}

const getNotificationsCtas = (
  notification: INotification,
  handleRead: (id: INotification['id'], status: ENotificationStatus) => void
) => (
  <Row className="justify-content-center">
    {notification.ctas &&
      notification.ctas
        .toSorted((a, b) => b.type.localeCompare(a.type))
        .map((cta, key) => (
          <Col xs="auto" className="mt-4" key={key}>
            <Button
              onClick={() => {
                cta.onClick()
                handleRead(
                  notification.id,
                  cta.type === ECtaType.PRIMARY
                    ? ENotificationStatus.READ
                    : ENotificationStatus.DISMISSED
                )
              }}
              color={EColor.BLUE}
              outline={cta.type === ECtaType.SECONDARY}
              className="px-4"
            >
              {cta.text}
            </Button>
          </Col>
        ))}
  </Row>
)

const getMinorNotifications = (
  notifications: Array<INotification>,
  handleRead: (id: INotification['id'], status: ENotificationStatus) => void
) =>
  notifications
    .filter((notification) => notification.type === ENotificationType.MINOR)
    .map((notification) => (
      <PopupNotification
        {...notification}
        key={notification.id}
        handleRead={handleRead}
      />
    ))

const getMajorNotifications = (
  notifications: Array<INotification>,
  handleRead: (id: INotification['id'], status: ENotificationStatus) => void
) =>
  notifications
    .filter((notification) => notification.type === ENotificationType.MAJOR)
    .map((notification) => (
      <ModalBlank
        key={notification.id}
        isOpen={notification.status === ENotificationStatus.UNREAD}
        callback={() =>
          handleRead(notification.id, ENotificationStatus.DISMISSED)
        }
        size={EModalSize.XL}
        content={
          <>
            <Title type="h4">{notification.title}</Title>
            <div>{notification.content}</div>
            {getNotificationsCtas(notification, handleRead)}
          </>
        }
      />
    ))

export const getToastNotifications = (notifications: IStoreNotifications) =>
  notifications.map((notification) => (
    <ToastNotification
      color={notification.color}
      dismissible={notification.dismissible}
      id={notification.id}
      key={notification.id}
      onCloseCallback={notification.onCloseCallback}
    >
      <Row className="align-items-center">
        <Col>
          {notification.text && (
            <>
              {Array.isArray(notification.text)
                ? notification.text.map((text, textIndex) => (
                    <span key={textIndex}>{text}</span>
                  ))
                : notification.text}
            </>
          )}
        </Col>
        {notification.cta && (
          <Col md="auto" className="pr-4 pt-3 pt-md-0">
            <Link
              href={notification.cta.href}
              target={notification.cta.target || '_self'}
            >
              <Button color={EColor.WHITE} outline={true}>
                {notification.cta.text}
              </Button>
            </Link>
          </Col>
        )}
      </Row>
    </ToastNotification>
  ))

import { FormGroup, FormHelper } from 'components'
import { Field, FieldProps, isString } from 'formik'
import { ChangeEvent, FC, useRef } from 'react'
import { uniqId } from 'utils'

import * as Style from './style'
import * as Type from './type'

const Radio: FC<Type.IRadio> = ({
  checked,
  disabled = false,
  helpText,
  id,
  label,
  name,
  type = 'radio',
  onChange,
  value,
  ...rest
}) => {
  const refUniqueId = useRef(uniqId('radio'))

  return (
    <Field
      name={name}
      children={({ form: { setFieldValue, errors }, field }: FieldProps) => {
        const error =
          errors && isString(value) && Object(errors?.[name || ''])?.[value]

        return (
          <FormGroup>
            <Style.Radio
              {...field}
              id={refUniqueId.current}
              type={type}
              checked={field.value === value}
              disabled={disabled}
              label={<span>{String(label)}</span>}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setFieldValue(field.name, value)
                onChange && onChange(event)
              }}
              {...rest}
            />
            <FormHelper
              fieldName={field.name}
              helpText={helpText}
              error={error}
            />
          </FormGroup>
        )
      }}
    />
  )
}

export default Radio

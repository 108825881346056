import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { deleteNotification } from 'state'
import { IStoreNotification } from 'state/type'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'
import { getNotificationDisplayDuration } from './utils'

const ToastNotification: FC<Type.IToastNotification> = ({
  color = EColor.BLUE,
  dismissible = true,
  id,
  children,
  onCloseCallback,
  ...rest
}) => {
  const dispatch = useDispatch()
  const handleClose = useCallback(
    (notificationToBeDeleted: IStoreNotification) => {
      dispatch(deleteNotification(notificationToBeDeleted))
      onCloseCallback && onCloseCallback()
    },
    [dispatch, onCloseCallback]
  )

  const notificationTimeout = useRef<number>(-1)
  const [hover, setHover] = useState(false)

  useEffect(() => {
    if (hover && notificationTimeout.current) {
      window.clearTimeout(notificationTimeout.current)
    } else if (dismissible && !hover) {
      notificationTimeout.current = window.setTimeout(
        () => handleClose({ color, id, text: children }),
        getNotificationDisplayDuration(children)
      )
    }
  }, [handleClose, hover, color, dismissible, id, children])

  /* Cancel timeout if component unmount before timeout end*/
  useEffect(
    () => () => {
      window.clearTimeout(notificationTimeout.current)
    },
    []
  )

  return (
    <Style.ToastNotification
      alignMiddle={true}
      className="mx-auto my-2"
      color={color}
      dismissible={dismissible}
      outline={false}
      toggle={() => handleClose({ color, id, text: children })}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      {...rest}
    >
      {children}
    </Style.ToastNotification>
  )
}

export default ToastNotification

import { API } from 'api/connector'
import { IProAccountAccount } from 'api/type'
import { iriToId } from 'utils'

import { IApiBusinessAccountBankAccount } from './type'

export const getBusinessAccountBankAccount = async (
  bankAccountId: IProAccountAccount['bankAccount']
): Promise<IApiBusinessAccountBankAccount> => {
  const bankAccount = await API.get(
    `/api/v1/bank_accounts/${iriToId(bankAccountId)}`
  )
  return bankAccount.data
}

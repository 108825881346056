import { IUseIframeListener } from './type'

const useIframeHeightListener = (): IUseIframeListener => {
  const isIframe = window.self !== window.top

  const resizeIframe = () => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        window.parent.postMessage(
          {
            action: 'donation-iframe.height',
            height: entry.contentRect.height,
          },
          '*'
        )
      }
    })
    observer.observe(document.body)

    return () => {
      observer.disconnect()
    }
  }

  return { isIframe, resizeIframe }
}

export default useIframeHeightListener

import { IGroupMenuBlock, IMenuLinkBlock } from 'api/type'
import { useSelector } from 'hooks'
import { useState } from 'react'
import { selectPage } from 'state'

import * as Type from './type'

const UseMenu = (
  currentPageItemId?: string,
  menuItems?: (IMenuLinkBlock | IGroupMenuBlock)[] | null | undefined
): Type.IUseMenu => {
  const page = useSelector(selectPage)
  const [headerIsAnimated, setHeaderIsAnimated] = useState(false)
  const [hoverPositionY, setHoverPositionY] = useState(-50)
  const [itemClickedId, setItemClickedId] = useState(page.menuItemActiveId)
  const [subItemClickedId, setSubItemClickedId] = useState(
    page.menuSubItemActiveId
  )
  const [itemClickedPreviousId, setItemClickedPreviousId] = useState('')
  const [itemHoverId, setItemHoverId] = useState('')
  const [menuOpened, setMenuOpened] = useState(false)

  const itemActiveId = currentPageItemId ?? page.menuItemActiveId

  const handleMouseLeave = () => {
    // Timeout to prevent blocking the calculation of the Y position when opening the header
    setTimeout(() => {
      if (headerIsAnimated) {
        handleMouseLeave()
      } else if (menuItems && itemActiveId) {
        // Restores the position and id of the active MenuItem when the mouse exits the menu

        // get html element of active menu item from id
        const itemActive = document.getElementById(itemActiveId)

        if (itemActive) {
          setHoverPositionY(
            itemActive.getBoundingClientRect().top + window.scrollY
          )
          setItemClickedPreviousId(itemClickedId)
          setItemClickedId(itemActiveId)
          setSubItemClickedId(page.menuSubItemActiveId) // only used by MASTER menu
          setItemHoverId(itemActiveId)
        }
      }
    }, 250)
  }

  return {
    handleMouseLeave,
    hoverPositionY,
    itemClickedId,
    itemClickedPreviousId,
    itemHoverId,
    subItemClickedId,
    menuOpened,
    setHeaderIsAnimated,
    setHoverPositionY,
    setItemClickedId,
    setItemClickedPreviousId,
    setItemHoverId,
    setMenuOpened,
  }
}

export default UseMenu

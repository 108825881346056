import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { HTMLAttributes, ReactElement } from 'react'
import { FlattenInterpolation, ThemeProps } from 'styled-components'
import { EColor } from 'types'

export enum EBackgroundBorderColor {
  SHADOW = 'shadow',
  DASHED = 'dashed',
  SOLID = 'solid',
}

export interface IBackground extends HTMLAttributes<HTMLDivElement> {
  border?: EBackgroundBorderColor
  borderColor?: EColor
  color?: EColor
  description?: string
  disabledContent?: boolean
  flip?: {
    active: boolean
    children: { back: ReactElement; front: ReactElement }
  }
  hoverShadow?: boolean
  shadow?: boolean
  hoverTransform?: boolean
  icon?: IconProp
  iconColor?: EColor
  hasCTA?: boolean
}

export interface BackgroundBorder {
  [key: string]: FlattenInterpolation<ThemeProps<string>>
}

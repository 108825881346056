import { EModalSize } from 'components/Modal/type'
import { FormikValues } from 'formik'
import { ReactElement } from 'react'

export interface IModalConfirm {
  isOpen: boolean
  confirmIsDisabled?: boolean
  buttonsText?: {
    confirm: string
    cancel: string
  }
  callbackConfirm: (values?: FormikValues) => Promise<void>
  callbackCancel: (values?: FormikValues) => Promise<void>
  size?: EModalSize
  title?: string
  description: string | ReactElement
  type?: EConfirmIntent
  isProcessing?: boolean
}

export enum EConfirmIntent {
  CONFIRM = 'confirm',
  DELETE = 'delete',
}

import { Container, Loader, NotificationsWrapper } from 'components'
import { useIframeHeightListener } from 'hooks'
import React, { cloneElement, FC, useMemo, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { ThemeProvider } from 'styled-components'
import { EColor } from 'types'
import { getTextColorFromColorHex } from 'utils'

import * as Style from './style'

export const LayoutPublic: FC = ({ children }) => {
  const [customColor, setCustomColor] = useState(EColor.BLUE)
  const [hasBodyOverflow, setHasBodyOverflow] = useState(true)

  const { isIframe, resizeIframe } = useIframeHeightListener()

  useEffectOnce(() => {
    if (isIframe) {
      resizeIframe()
      setHasBodyOverflow(false)
    }
  })

  const customTheme = useMemo(
    () => ({
      publicMainColor: customColor,
      textColor: getTextColorFromColorHex(customColor),
    }),
    [customColor]
  )

  const page = cloneElement(children as React.ReactElement, {
    setCustomColor,
  })

  return (
    <ThemeProvider theme={customTheme}>
      {!hasBodyOverflow && <Style.CSSBodyOverflowHidden />}
      <Container role="main" tag="main" isMenuHidden={true}>
        <Style.ContentWrapper>{page}</Style.ContentWrapper>
      </Container>
      <NotificationsWrapper />
      <Loader />
    </ThemeProvider>
  )
}
